import { graphql, PageProps, StaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { SponsorBooth, SponsorsGrid } from "../../components/sponsor";
import {
  StyledParagraph,
  StyledPrimaryHeader,
  StyledSecondaryHeader,
} from "../../components/styled";
import useAuthModal from "../../hooks/useAuthModal";

interface AgendaPageProps extends PageProps {
  data: {
    allSponsorsJson: {
      sponsors: any[];
    };
  };
  pageContext: any;
}

const TechExpoPage: FunctionComponent<AgendaPageProps> = ({
  pageContext,
  data,
}) => {
  const { sponsor, next, prev, resources } = pageContext;
  const { sponsors } = data.allSponsorsJson;
  const { user } = useAuthModal({ authRequired: true });

  return (
    <Layout className="page__tech-expo">
      {user && (
        <>
          <header>
            <StyledPrimaryHeader>Tech Expo</StyledPrimaryHeader>
            <StyledSecondaryHeader>
              Thank you to our valued sponsors!
            </StyledSecondaryHeader>
            <StyledParagraph>
              Click on our sponsors below to access whitepapers, videos and
              more!
            </StyledParagraph>
          </header>
          <SponsorsGrid sponsors={sponsors} />
          {sponsor && (
            <SponsorBooth
              open={sponsor}
              resources={resources}
              sponsor={sponsor}
              next={next}
              prev={prev}
              onClose={() => console.log("reset route")}
            />
          )}
        </>
      )}
    </Layout>
  );
};

export default ({ props, pageContext }) => (
  <StaticQuery
    query={graphql`
      query AppTechExpoVirtualQuery {
        allSponsorsJson {
          sponsors: nodes {
            name
            logo
            email
            slug
            url
            width
            sponsorLevel
          }
        }
      }
    `}
    render={(data) => (
      <TechExpoPage pageContext={pageContext} data={data} {...props} />
    )}
  />
);

export const Head = () => {
  return (
    <SEO title="Tech Expo">
      <script type="application/ld+json">{JSON.stringify({})}</script>
    </SEO>
  );
};

import React from "react";
import { useTracking } from "../../context";

const BoothResources = ({ resources = [] }) => {
  const tracking = useTracking();

  const handleResourceClick = (resource: {}) => {
    tracking.trackEvent({
      action: "pageview",
      event: "Interaction",
      payload: {
        href: location.href,
        type: "resourseClick",
        resource: resource,
      },
    });
  };

  return (
    <div className="resourceWindow">
      {resources.map((resource, index) => (
        <div key={index} className="resourceItem">
          <div className="resourceTitle">{resource.title}&nbsp;</div>
          <div className="viewContain">
            <a
              onClick={() => handleResourceClick(resource)}
              className="viewBtn"
              href={resource.url}
              target="_blank"
            >
              {resource.cta.toUpperCase()}{" "}
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BoothResources;

import { withPrefix } from "gatsby";
import React, {
  FunctionComponent,
  MouseEvent,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import { SliderProps } from "../interfaces";
import Modal from "../modals";

const Slider: FunctionComponent<SliderProps> = ({
  open,
  index,
  onClose,
  onNext,
  onPrev,
  children,
}) => {
  const [modalState, setModalState] = useState(open);

  useEffect(() => {
    open && setModalState(open);
  }, [open]);

  const handleOnClose = () => {
    setModalState(false);
    onClose && onClose();
  };

  const handleOnPrev = (event: MouseEvent) => {
    event.preventDefault();
    onPrev && onPrev();
  };

  const handleOnNext = (event: MouseEvent) => {
    event.preventDefault();
    onNext && onNext();
  };

  return (
    <Modal open={modalState} onClose={handleOnClose}>
      <StyledSlider currentIndex={index}>
        <a className="control close" onClick={handleOnClose}>
          <img src={withPrefix(`/images/Close.svg`)} alt="close" />
        </a>
        <div className="control prev" onClick={handleOnPrev}>
          <img
            src="https://cdn.fedscoop.com/upgather/redhatgov/desktop/ArrowLeft.svg"
            alt="previous"
            className="deskArrow"
            onMouseOver={(e) =>
              (e.currentTarget.src =
                "https://sng-client-assets.s3.amazonaws.com/fedscoop/upgather/fortinet/tech-expo/Arrow+Left+Blue+-+Hover.svg")
            }
            onMouseOut={(e) =>
              (e.currentTarget.src =
                "https://sng-client-assets.s3.amazonaws.com/fedscoop/upgather/fortinet/tech-expo/Arrow+Left+Black.svg")
            }
          />
          <img
            src="https://sng-client-assets.s3.amazonaws.com/fedscoop/upgather/fortinet/tech-expo/Arrow+Left+Black.svg"
            alt="previous"
            className="mobileArrow"
          />
        </div>
        <div className="control next" onClick={handleOnNext}>
          <img
            src="https://sng-client-assets.s3.amazonaws.com/fedscoop/upgather/fortinet/tech-expo/Arrow+Right+Black.svg"
            alt="next"
            className="deskArrow"
            onMouseOver={(e) =>
              (e.currentTarget.src =
                "https://sng-client-assets.s3.amazonaws.com/fedscoop/upgather/fortinet/tech-expo/Arrow+Right+Blue+-+Hover.svg")
            }
            onMouseOut={(e) =>
              (e.currentTarget.src =
                "https://sng-client-assets.s3.amazonaws.com/fedscoop/upgather/fortinet/tech-expo/Arrow+Right+Black.svg")
            }
          />
          <img
            src="https://cdn.fedscoop.com/upgather/redhatgov/desktop/ArrowRight.svg"
            alt="previous"
            className="mobileArrow"
          />
        </div>
        <div className="content">{children}</div>
      </StyledSlider>
    </Modal>
  );
};

const StyledSlider = styled.aside`
  display: grid;
  grid-template: 20px 1fr / 20px 1fr 1fr 20px;
  width: 1200px;

  .control.close {
    grid-area: 1/1/1/5;
    width: 40px;
    height: 40px;
    justify-self: end;
    outline: none;
    background-color: #fff;
    margin-top: -30px;
  }

  .control.prev,
  .control.next {
    align-self: center;
    width: 72px;
    height: 72px;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .control.prev {
    grid-area: 2/1/3/2;
    margin-left: -36px;
  }

  .control.next {
    grid-area: 2/4/3/5;
    margin-left: -14px;
  }
  .mobileArrow {
    display: none;
  }

  .content {
    grid-area: 2/1/3/5;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: left 348px;
    background-size: 500px;
    color: black;
    padding-bottom: 50px;
  }

  button {
    background: #5d5d5d;
  }
  @media only screen and (min-width: 1440px) and (max-width: 1600px) {
    width: 1180px;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1440px) {
    width: 1182px;
    height: 658px;
  }
  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    width: 918px;
    height: 550px;
  }
  @media only screen and (min-width: 960px) and (max-width: 1024px) {
    width: 844px;
  }

  @media only screen and (min-width: 768px) and (max-width: 959px) {
    width: 680px;
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    background-color: transparent;
    grid-template: 20px 1fr 75px / 20px 1fr 1fr 20px;
    width: 90%;
    max-width: 95vw !important;
    margin: 0 auto;
    padding-top: 80px;
    padding-right: 10px;
    padding-left: 10px;

    .sponsorImage {
      margin: 0 auto;
      padding-top: 30px;
    }
    .content {
      grid-area: 2/1/3/5;

      background-image: none;
      padding-bottom: 70px;
    }

    .control.prev,
    .control.next {
      margin: 0px;
      width: 45px;
      height: 45px;
      margin-top: -136px;
    }

    .control.prev {
      grid-area: 3/1/3/2;
    }
    .control.next {
      grid-area: 3/4/3/5;
      justify-self: end;
    }
    .mobileArrow {
      display: block;
      width: 35px;
    }
    .deskArrow {
      display: none;
    }
  }
  @media only screen and (min-width: 120px) and (max-width: 320px) {
    background-color: transparent;
    grid-template: 20px 1fr 75px / 20px 1fr 1fr 20px;
    width: 90vw;
    height: 80vh;
    margin: 0 auto;
    .sponsorImage {
      margin: 0 auto;
      padding-top: 30px;
    }
    .content {
      grid-area: 2/1/3/5;
      padding-bottom: 70px;
      background-image: none;
    }

    .control.prev,
    .control.next {
      margin: 0px;
      width: 45px;
      height: 45px;
      margin-top: -136px;
    }

    .control.prev {
      grid-area: 3/1/3/2;
    }
    .control.next {
      grid-area: 3/4/3/5;
      justify-self: end;
    }
    .mobileArrow {
      display: block;
    }
    .deskArrow {
      display: none;
    }
  }

  @media only screen and (max-width: 1330px) {
    width: 100%;
  }
`;

export default Slider;

import { Link, withPrefix } from "gatsby";
import React, { FunctionComponent } from "react";
import { Sponsor } from "../interfaces";
import { StyledSponsorGrid } from "../styled";

const SponsorsGrid: FunctionComponent<any> = ({ sponsors = [] }) => {
  return (
    <>
      <StyledSponsorGrid>
        {sponsors
          .filter((page) => page.slug)
          .map(
            (sponsor: Sponsor, index: number) =>
              sponsor.name !== "Nvidia" && (
                <Link
                  key={index}
                  to={sponsor.slug.toLocaleLowerCase()}
                  data-index={index}
                >
                  <div key={index} className="sponsor">
                    <img
                      src={withPrefix(sponsor.logo)}
                      alt={sponsor.name}
                      width={sponsor.width}
                    />
                  </div>
                </Link>
              )
          )}
      </StyledSponsorGrid>
    </>
  );
};

export default SponsorsGrid;

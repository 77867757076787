import { navigate, useLocation } from "@reach/router";
import { withPrefix } from "gatsby";
import React, {
  FunctionComponent,
  MouseEvent,
  useEffect,
  useState,
} from "react";
import { useTracking } from "../../context";
import useEventDetails from "../../hooks/useEventDetails";
import { SponsorBoothProps } from "../interfaces";
import Slider from "../sliders";
import { StyledSponsorBooth } from "../styled";
import BoothResources from "./BoothResources";

const SponsorBooth: FunctionComponent<SponsorBoothProps> = ({
  open,
  onClose,
  sponsor = {},
  next = {},
  prev = {},
  resources = [],
}) => {
  const [openResources, setResourcesState] = useState(false);
  const [openReadMore, setReadMoreState] = useState(false);
  const [logo, updateLogo] = useState(sponsor.logo);
  const tracking = useTracking();
  const location = useLocation();
  const { virtual } = useEventDetails();
  const urlPrefix = virtual ? "/virtual/tech-expo" : "/tech-expo";

  // hotfix for intel
  useEffect(() => {
    if (sponsor.slug == "intel") {
      sponsor.email = "publicsector@intel.com";
    }
    if (["dell-technologies-polyverse", "polyverse"].includes(sponsor.slug)) {
      updateLogo(
        "https://cdn.cyberscoop.com/upgather/cybertalks/sponsors/Dell_Poly.png"
      );
    }
    if (["mulesoft", "salesforce"].includes(sponsor.slug)) {
      updateLogo(
        "https://cdn.fedscoop.com/upgather/it-modernization-summit/sponsors/MuleSoftSalesforce.png"
      );
    }
  }, [sponsor]);

  const getEmail = () => {
    if (sponsor.email.includes("@")) {
      window.open(
        `mailto:${sponsor.email}?subject=IT Modernization & AI Summit - Meeting Request`
      );
    } else {
      window.open(`${sponsor.email}`);
    }
  };

  const handleOnClose = () => {
    onClose && onClose();
    navigate(withPrefix(`${urlPrefix}`), {
      state: { from: "sponsorBoothExit" },
    });
  };

  const handleOnNext = () => {
    next && navigate(withPrefix(`${urlPrefix}/${next.slug}`));
  };

  const handleOnPrev = () => {
    prev && navigate(withPrefix(`${urlPrefix}/${prev.slug}`));
  };

  const handleResourcesClick = (event: MouseEvent) => {
    event.preventDefault();
    setResourcesState(true);
    tracking.trackEvent({
      action: "pageview",
      event: "Interaction",
      payload: {
        href: location.href,
        type: "boothResourceClick",
        sponsor: sponsor.name,
      },
    });
  };

  const handleEmailClick = (event: MouseEvent) => {
    event.preventDefault();
    getEmail();
    tracking.trackEvent({
      action: "pageview",
      event: "Interaction",
      payload: {
        href: location.href,
        type: "boothEmailClick",
        sponsor: sponsor.name,
      },
    });
  };

  const getVideoEmbedUrl = () => {
    let url = `https://www.youtube.com/embed/${sponsor.welcomeVideoId}?rel=0`;
    if (sponsor.welcomeVideoProvider) {
      switch (sponsor.welcomeVideoProvider) {
        case "vimeo":
          url = `https://player.vimeo.com/video/${sponsor.welcomeVideoId}?rel=0&title=0&byline=0&portrait=0`;
          break;
        case "vidyard":
          url = `https://play.vidyard.com/${sponsor.welcomeVideoId}.html?`;
          break;
        default:
          break;
      }
    }
    return url;
  };

  return (
    <Slider
      open={open}
      onPrev={handleOnPrev}
      onNext={handleOnNext}
      onClose={handleOnClose}
    >
      <StyledSponsorBooth>
        <div className="sponsorImage">
          {(sponsor.welcomeVideoId || sponsor.boothBannerUri) && (
            <img
              src={withPrefix(logo)}
              alt={sponsor.name}
              width={sponsor.widthModal}
            />
          )}
          {resources.length > 0 && sponsor.slug != "bar" && (
            <a className="boothBtn" onClick={handleResourcesClick}>
              RESOURCES
            </a>
          )}
          {/* TODO: email could be a link to a website. like in Dell's booth */}
          <a className="boothBtn" onClick={handleEmailClick}>
            MEET WITH US
          </a>
        </div>

        <div className={`boothFeature ${openResources ? "active" : ""}`}>
          {openResources && (
            <a
              className="resourceBackBtn"
              onClick={() => setResourcesState(false)}
            >
              <img src="https://s3.amazonaws.com/sng-global-web-assets/images/upgather/hpe/ArrowLeft.svg" />
              Go Back
            </a>
          )}
          {openResources && <h2 className="resourceH2">Resources</h2>}
          {openResources || (
            <div className="boothContain">
              <div className="ytvid">
                {sponsor.welcomeVideoId ? (
                  <VideoIframe src={getVideoEmbedUrl()} />
                ) : (
                  <img
                    src={withPrefix(sponsor.boothBannerUri || sponsor.logo)}
                    alt={sponsor.name}
                    className={sponsor.name}
                  />
                )}
              </div>
              {sponsor.description && (
                // TODO: sponAbout should be scrollable when openReadMore == true
                <div className="sponAbout">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: openReadMore
                        ? sponsor.description
                        : `${sponsor.description.slice(0, 180)}...`,
                    }}
                    className={`sponBox ${openReadMore ? "active" : ""}`}
                  ></div>
                  <a
                    className={`readMoreBtn ${openReadMore ? "active" : ""}`}
                    onClick={() => setReadMoreState(true)}
                  >
                    Read more ›
                  </a>
                </div>
              )}
            </div>
          )}
          {openResources && <BoothResources resources={resources} />}
        </div>
      </StyledSponsorBooth>
    </Slider>
  );
};

const VideoIframe = ({ src }) => {
  return (
    <iframe
      width="100%"
      height="100%"
      src={src}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
};
export default SponsorBooth;
